<template >
  <b-container fluid class="container">
    <b-row align-h="center" align="center">
      <b-col cols="12">
        <br>
        <h6>Da click en siguiente botón</h6>
        <br>
      </b-col>
      <b-col cols="12">
        <b-button size="lg" variant="success" @click="toCatalogo">
          Ir a Sistema de Trámites y Pagos En Línea  <b-icon icon="arrow-right-circle"/>
        </b-button>
      </b-col>
      <b-col cols="12">
        <br>
        <span></span>
      </b-col>    
    </b-row>    
  </b-container>
</template>

<script>
  import Vue from 'vue';
  import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
  Vue.use(BootstrapVue)
  Vue.use(BootstrapVueIcons)

  export default {
    computed: {
      
    },
    data() {
      return {
      
      }
    },
    methods:{
      toCatalogo(){
        window.open("https://gobinet.appspot.com/"+ this.$route.params.municipio + '/catalogotramites', '_blank').focus();
      }
    }
    
  }
</script>
<style >

</style>